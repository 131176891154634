import {A, usePath, navigate} from 'hookrouter';
import { useEffect } from 'react';
import { useSesionContext } from '../context';

export default function HeaderNav(){
    const path = usePath();
    const { actualLocal } = useSesionContext();
    const mostrar = true;

    useEffect(() => {
        if (!actualLocal){
            navigate('/');
        }
    })
    return (
        <nav className="h-10 bg-red-500 text-white flex items-center justify-between">
            <div className="w-2/5 flex items-center justify-start">
                <span className="ml-2 font-bold">Draco</span>
                <span className="ml-2"> {actualLocal?.name || 'cargando...'}</span>
            </div>
            {
                mostrar ?
                <div className="w-2/5 h-full flex items-center justify-between divide-x">
                    {
                        path !== '/clients' ?
                        <A className="transition duration-150 h-full w-full flex items-center justify-center hover:bg-white hover:text-red-500" href="/clients">Clientes</A>
                        :
                        <A className="transition duration-150 h-full w-full flex items-center justify-center hover:bg-white hover:text-red-500" href="/index">Inicio</A>
                    }
                    {
                        path !== '/addProduct' ?
                        <A className="transition duration-150 h-full w-full flex items-center justify-center hover:bg-white hover:text-red-500" href="/addProduct">Agregar producto</A>
                        :
                        <A className="transition duration-150 h-full w-full flex items-center justify-center hover:bg-white hover:text-red-500" href="/index">Inicio</A>
                    }
                </div>
                :
                null
            }
        </nav>
    )   
}