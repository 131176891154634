import { navigate } from "hookrouter";
import { useEffect } from "react"

export default function Loader(){
    useEffect(() => {
        navigate('/home');
    }, []);

    return (
        <nav>
            cargando pa
        </nav>
    )   
}