import { useEffect, useState } from "react"
import { useSesionContext } from "../../app/context";

export default function TableWorkers(){
    const {register,addRegister} = useSesionContext();
    const [lista,setLista] = useState({});
    const [monto,setMonto] = useState(0);
    const [elegido,setElegido] = useState(null);
    const [cantidad, setCantidad] = useState(0);

    function elegir(e){
        setMonto(0);
        setElegido(e);
    }

    function cancelar(){
        setMonto(0);
        setElegido(null);
    }

    function pagar(e){
        const doc = {
            desc: `Pago a ${e.sobrenombre}`,
            amount: parseInt(monto),
            empleado: elegido,
            card: false,
            tipo: 'Pago',
            type:'Egreso',
            sobrenombre: e.sobrenombre
        };
        addRegister(doc);
        setMonto(0);
        setElegido(null);
    }
    
    useEffect(() => {
        if (register.length !== cantidad){
            let tabla = {};
            register.forEach(reg => {
                if (reg.sobrenombre){
                    if (tabla[reg.sobrenombre] === undefined){
                        if (reg.tipo === 'Perforación'){
                            tabla[reg.sobrenombre] = {_id:reg.empleado, sobrenombre: reg.sobrenombre, perfos: reg.cantidad, tatuajes: 0, pagos: 0};
                        }
                        if (reg.tipo === 'Tatuaje'){
                            tabla[reg.sobrenombre] = {_id:reg.empleado, sobrenombre: reg.sobrenombre, perfos: 0, tatuajes: 1, pagos: 0};
                        }
                        if (reg.tipo === 'Pago'){
                            tabla[reg.sobrenombre] = {_id:reg.empleado, sobrenombre: reg.sobrenombre, perfos: 0, tatuajes: 0, pagos: reg.amount};
                        }
                    } else {
                        if (reg.tipo === 'Perforación'){
                            tabla[reg.sobrenombre].perfos += reg.cantidad;
                        }
                        if (reg.tipo === 'Tatuaje'){
                            tabla[reg.sobrenombre].tatuajes += 1;
                        }
                        if (reg.tipo === 'Pago'){
                            tabla[reg.sobrenombre].pagos += reg.amount;
                        }
                    }
                }
            });
            setLista(tabla);
            setCantidad(register.length);
        }
    });
    function renderPago(emp){
        if (elegido !== emp._id){
            return (
                <div className="w-full flex items-center justify-start">
                    <button onClick={()=>elegir(emp._id)} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Pagar</button>    
                </div>
            )
        } else {
            return (
                <div className="w-full flex items-center justify-start">
                    <input value={monto} onChange={(e) => setMonto(e.target.value)} placeholder="0" className="p-2 shadow" type="text"/>
                    <button onClick={()=>pagar(emp)} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Confirmar</button>  
                    <button onClick={cancelar} className="p-2 bg-gray-500 text-white hover:bg-gray-600 cursor-pointer">Cancelar</button>   
                </div>
            )
        }     
    }
    return (
        <div className="w-full h-full overflow-y-scroll lista">
                {
                    Object.values(lista).map((emp, key) => (
                        <div key={key} className="w-full h-24 border-b border-gray-400 p-4">
                            <div className="w-full flex items-center justify-start">
                                <div className="w-full flex flex-col items-center justify-center">
                                    <span className="font-bold text-xs">Empleado</span>
                                    {emp.sobrenombre}
                                </div>
                                <div className="w-full flex flex-col items-center justify-center">
                                    <span className="font-bold text-xs">Tatuajes</span>
                                    {emp.tatuajes}
                                </div>
                                <div className="w-full flex flex-col items-center justify-center">
                                    <span className="font-bold text-xs">Perforaciones</span>
                                    {emp.perfos}
                                </div>
                                <div className="w-full flex flex-col items-center justify-center">
                                    <span className="font-bold text-xs">Pago</span>
                                    ${emp.pagos}
                                </div>
                            </div>
                            {renderPago(emp)}
                        </div>
                    )
                )
                }
        </div>
    )
}