import { useEffect, useState } from "react";
import Popup from 'reactjs-popup';
import { useSesionContext } from "../../app/context"

export default function History(props){
    const { addRegister, register, delRegister, boxToday, actualLocal } = useSesionContext();
    const [lista, setLista] = useState([]);
    const [onEdit, setOnEdit] = useState(false);
    const [dataEdit, setDataEdit] = useState(false);
    const [reg, setReg] = useState(null);

    // Editor
    const [amount, setAmount] = useState(0);
    const [monto, setMonto] = useState(0);
    const [cant, setCant] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [tarjeta, setTarjeta] = useState(false);
    const [clave, setClave] = useState('');

    function selectRow(object){
        setReg(object.data);
        setOnEdit(true);
    }
    function cancelEdit(){
        setReg(null);
        setOnEdit(false);
        setDataEdit(false);
    }

    function closeDataEdit(){
        setDataEdit(false);
    }

    function openDataEdit() {
        setTarjeta(reg.tarjeta);
        setDescuento((reg.descuento || 0));
        setCant(reg.cantidad);
        if (reg.tipo === 'Tatuaje'){
            setMonto(reg.amount);
        } else {
            setMonto(reg.base * reg.cantidad);
        }
        setDataEdit(true);
    }

    function anular(close){
        if (actualLocal.name === 'San Martin') {
            if (clave.toLowerCase().includes('genial')) {
                setClave('');
                delRegister(reg);
                setReg(null);
                setOnEdit(false);
                close();
            } else {
                alert('clave incorrecta.');
                setReg(null);
                setOnEdit(false); 
                close();
            }
        } else {
            delRegister(reg);
            setReg(null);
            setOnEdit(false); 
        }
        
    }

    function editarMovimiento(){
        const newMov = reg;
        newMov.tarjeta = tarjeta;
        newMov.descuento = descuento;
        newMov.cantidad = cant;
        newMov.amount = monto;
        addRegister(newMov);
        closeDataEdit();
    }

    function RowRender(object) {
        return (
            <div onClick={() => selectRow(object)} className={`transition duration-150 w-full h-10 border-b border-gray-400 ${object.data.tipo === 'Perforación' ? 'bg-pink-200' : ''} ${object.data.tipo === 'Tatuaje' ? 'bg-red-200' : ''} flex items-center justify-between p-2 cursor-pointer hover:bg-red-500 hover:text-white`}>
                <div className="font-bold">{`${object.data.date[3]}:${object.data.date[4] < 10? `0${object.data.date[4]}` : object.data.date[4]}`} hs. - {object.data.desc}</div>
                <div>{`x${object.data.cantidad}`}</div>
                <div>{`${object.data.type === 'Egreso'? '-' : ''}$${object.data.amount}`}</div>
            </div>
        );
    }

    function renderListaHistorial() {
        return lista.sort((a,b)=> {
            let dateA = new Date(a.date[0],a.date[1],a.date[2],a.date[3],a.date[4],a.date[5]);
            let dateB = new Date(b.date[0],b.date[1],b.date[2],b.date[3],b.date[4],b.date[5]);
            return dateB.getTime() - dateA.getTime(); 
        } ).map((reg,i)=> <RowRender key={i} data={reg} />);
    }
    function renderCajaCerrada(){
        return (
            <div className="w-full h-10 flex items-center justify-around text-white bg-red-500 text-xs">
                <div className="w-full flex items-center justify-center">Caja cerrada</div>
                <div className="w-full flex items-center justify-center">Cambio: ${boxToday.cambio}</div>
                <div className="w-full flex items-center justify-center">Sobrante: ${boxToday.sobrante}</div>
            </div>
        );
    }

    useEffect(()=> {
        if (reg){
            setMonto(Math.round( ( (reg.base * cant) - ((reg.base * descuento) / 100) ) ) );
        }
    },[cant, descuento]);

    useEffect(() => {
        setLista(register);
    }, [register]);

    if (onEdit){
        if (dataEdit){
            return (
                <div>
                    <div className="w-full flex p-2">
                       <span className="font-bold">Hora:</span> {`${reg.date[3]}:${reg.date[4] < 10? `0${reg.date[4]}` : reg.date[4]}`} hs.
                    </div>
                    <div className="w-full flex p-2">
                       <span className="font-bold">Detalle: </span>{reg.desc}
                    </div>
                    <div className="w-full flex p-2">
                       <span className="font-bold">Tipo: </span>{reg.type}
                    </div>
                    { !!reg.item ?
                        <div className="w-full flex p-2">
                            <span className="font-bold">Item: </span>{reg.item}
                        </div>
                        : null
                    }
                    {
                        reg.tipo !== 'Tatuaje' ?
                        <div className="w-full flex p-2">
                            <span className="font-bold">Cantidad: </span><input type="number" value={cant} onChange={(e) => setCant(parseInt(e.target.value))} />
                        </div>
                        :
                        null
                    }
                    {
                        reg.tipo !== 'Tatuaje'?
                        <div className="w-full flex p-2">
                            <span className="font-bold">Descuento: </span>
                            <select value={descuento} onChange={(e) => setDescuento(parseInt(e.target.value))}>
                                <option value={0}>Ninguno</option>
                                <option value={10}>%10</option>
                                <option value={15}>%15</option>
                                <option value={20}>%20</option>
                            </select>
                        </div>
                        :
                        null
                    }
                    <div className="w-full flex p-2">
                        <span className="font-bold">Metodo: </span>
                        <select value={tarjeta} onChange={(e) => setTarjeta((e.target.value=== 'true'))}>
                            <option value={false}>Efectivo</option>
                            <option value={true}>Tarjeta</option>
                        </select>
                    </div>
                    {
                        reg.tipo === 'Tatuaje' ?
                        <div className="w-full flex p-2">
                            <span className="font-bold">Monto: </span>$ <input type="tel" value={monto} onChange={(e) => setMonto(parseInt(e.target.value))} />
                        </div>
                        :
                        <div className="w-full flex p-2">
                            <span className="font-bold">Monto: </span>$ {monto}
                        </div>
                    }
                    <div className="w-full flex justify-between p-2">
                        <button className="p-2 bg-red-500 text-white rounded shadow" onClick={closeDataEdit}>Cancelar</button>
                        <button className="p-2 bg-red-500 text-white rounded shadow" onClick={editarMovimiento}>Guardar</button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="w-full h-full flex flex-col items-center justify-start overflow-y-scroll lista">
                    <div className="w-full flex p-2">
                        <button className="p-2 bg-red-500 text-white rounded shadow" onClick={cancelEdit}>Volver</button>
                    </div>
                    <div className="w-full flex p-2">
                       <span className="font-bold">Hora:</span> {`${reg.date[3]}:${reg.date[4] < 10? `0${reg.date[4]}` : reg.date[4]}`} hs.
                    </div>
                    <div className="w-full flex p-2">
                       <span className="font-bold">Detalle: </span>{reg.desc}
                    </div>
                    <div className="w-full flex p-2">
                       <span className="font-bold">Tipo: </span>{reg.type}
                    </div>
                    <div className="w-full flex p-2">
                       <span className="font-bold">Cantidad: </span>{reg.cantidad}
                    </div>
                    <div className="w-full flex p-2">
                        <span className="font-bold">Monto: </span>$ {reg.amount}
                    </div>
                    {
                        reg.descuento ?
                        <div className="w-full flex p-2">
                            <span className="font-bold">Descuento: </span>% {reg.descuento}
                        </div>
                        :
                        null
                    }
                    <div className="w-full flex p-2">
                        <span className="font-bold">Metodo: </span>{reg.tarjeta ? 'Tarjeta' : 'Efectivo'}
                    </div>
                    <div className="w-full flex justify-between p-2">
                        {actualLocal.name === 'San Martin'
                            ? <Popup 
                                trigger={<button className="p-2 bg-red-500 text-white rounded shadow">Anular</button>}
                                position='center center'
                                arrow={false} 

                            >
                                {close => (
                                    <>
                                        <div className='p-10 bg-white rounded shadow flex flex-col items-center'>
                                            <div className='font-bold text-xl'>Introduce la clave para eliminar el movimiento</div>
                                            <input placeholder="Clave de seguridad" type="password" value={clave} onChange={(e)=>setClave(e.target.value)} className="p-2 shadow w-full"/>
                                            <button onClick={() => anular(close)} className="p-2 bg-red-500 text-white rounded shadow">Confirmar</button>
                                        </div>
                                    </>
                                )}
                            </Popup>
                            : <button onClick={anular} className="p-2 bg-red-500 text-white rounded shadow">Anular</button>
                        }
                        <button className="p-2 bg-red-500 text-white rounded shadow" onClick={openDataEdit}>Editar</button>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <div className="w-full h-full overflow-y-scroll lista">
                {
                    boxToday ? 
                        renderCajaCerrada()
                    :
                        null
                }
                {
                    renderListaHistorial()
                }
            </div>
        )
    }
}
