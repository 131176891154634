import 'reactjs-popup/dist/index.css';
import { useEffect, useState } from "react";
import { useSesionContext } from "../../app/context"
import ControllerRegister from "./controllerRegister";
import ControllerNotes from "./controllerNotes";
import Table from "./Table";
import History from './history';
import TableWorkers from "./tableWorkers";

export default function Index(){
    const { connect, getWorkers, getProducts, getRegister, getClients } = useSesionContext();

    useEffect(() => {
        connect();
        getWorkers();
        getProducts();
        getRegister();
        getClients();
    }, []);

    return (
        <div className="w-screen alto bg-gray-300 flex items-center justify-start">
            <div className="w-1/3 alto border-r border-gray-400">
                <ControllerRegister />
                <History />
            </div>
            <div className="w-1/3 alto border-gray-400">
                <Table/>
            </div>
            <div className="w-1/3 alto border-l border-gray-400">
                <ControllerNotes />
                <TableWorkers/>
            </div>
        </div>
    )   
}