import { useRoutes, usePath } from 'hookrouter';
import { SesionProvider } from './app/context';
import HeaderNav from './app/HeaderNav/headerNav';
import Index from './routes/index/index';
import Clients from './routes/clients/clients';
import Loader from './routes/loader/loader';
import Login from './routes/login/login';
import AddProduct from './routes/addProduct/addProduct';

function App() {
  const path = usePath();
  const routes = {
    '/': () => <Login/>,
    '/clients': () => <Clients/>,
    '/home': () => <Index/>,
    '/addProduct': () => <AddProduct/>
  }
  const routerResult = useRoutes(routes);
  return (
    <main className="App">
      <SesionProvider>
        { path !== '/' ?
          <HeaderNav />
        :
          null
        }
        {routerResult || <Loader/>}
      </SesionProvider>
    </main>
  );
}

export default App;
