import { useEffect, useState } from "react";
import { useSesionContext } from "../../app/context"

export default function Table(){
    const { register, setDataTable } = useSesionContext();
    const [tabla, setTabla] = useState({
        total: 0,
        tarjeta: 0,
        efectivo: 0,
        pagos: 0,
        gastos: 0,
        neto: 0,
        cambio: 0,
        sobrante: 0
    });

    useEffect(() => {
        const newTabla = {
            total: 0,
            tarjeta: 0,
            efectivo: 0,
            pagos: 0,
            gastos: 0,
            neto: 0,
            cambio: 0,
            sobrante: 0
        };
        register.forEach( reg => {
            //const reg = register[i];
            if (reg.cambio){
                newTabla.cambio = reg.cambio;
            }
            if (reg.amount){
                newTabla.total += (reg.type === 'Egreso' ? (-1 * parseInt(reg.amount)) : parseInt(reg.amount));
                newTabla.neto += (reg.type === 'Egreso' ? 0 : parseInt(reg.amount));
            }
            if (reg.type === "Ingreso"){
                if (reg.tarjeta){
                    newTabla.tarjeta += parseInt(reg.amount);
                } else {
                    newTabla.efectivo += parseInt(reg.amount);
                }
            } else if (reg.type === "Egreso") {
                if (reg.sobrenombre){
                    newTabla.pagos += parseInt(reg.amount);
                } else {
                    newTabla.gastos += parseInt(reg.amount);
                }
            }
        });
        setTabla(newTabla);
        setDataTable(newTabla);
    }, [register])

    function renderTable(){
        return (
            <div className="w-full h-40 flex flex-col items-center justify-center font-bold">
                <div className="w-full flex items-center justify-around">
                    <div className="w-full items-center justify-center flex text-xl"><span className="text-lg mr-2">Total: </span> ${tabla.total}</div>
                    <div className="w-full items-center justify-center flex text-xl"><span className="text-lg mr-2">Neto: </span> ${tabla.neto}</div>
                </div>
                <div className="w-full flex items-center justify-around">
                    <div className="w-full items-center justify-center flex text-xl"><span className="text-lg mr-2">Tarjeta: </span> ${tabla.tarjeta}</div>
                    <div className="w-full items-center justify-center flex text-xl"><span className="text-lg mr-2">Efectivo: </span> ${tabla.efectivo}</div>
                </div>
                <div className="w-full flex items-center justify-around">
                    <div className="w-full items-center justify-center flex text-xl"><span className="text-lg mr-2">Gastos: </span> ${tabla.gastos}</div>
                    <div className="w-full items-center justify-center flex text-xl"><span className="text-lg mr-2">Pagos: </span> ${tabla.pagos}</div>
                </div>
                <div className="w-full flex items-center justify-around">
                    <div className="w-full items-center justify-center flex text-xl"><span className="text-lg mr-2">Sobrante: </span> ${tabla.sobrante}</div>
                    <div className="w-full items-center justify-center flex text-xl"><span className="text-lg mr-2">Cambio: </span> ${tabla.cambio}</div>
                </div>
            </div>
        );
    }

    return renderTable();
}