import {useEffect, useState} from 'react';
import { useSesionContext } from '../../app/context';
import Document from './document.jsx';

export default function Clients(){
    const {addClient, clients, getClients, actualLocal, today, clientType, setClientType,clientPagination} = useSesionContext();
    const hoy = new Date();
    const [clientList, setClientList] = useState([]);
    const [client, setClient] = useState({});
    const [name, setName] = useState('');
    const [document, setDocument] = useState('');
    const [date, setDate] = useState(hoy.getDate());
    const [month, setMonth] = useState(hoy.getMonth() + 1);
    const [year, setYear] = useState(hoy.getFullYear());
    const [phone, setPhone] = useState('');
    const [instagram, setInstagram] = useState('');
    const [service, setService] = useState('Tatuaje');
    const [documentShow, setDocumentShow] = useState(false);

    const [reg, setReg] = useState(-1);
    const [birth, setBirth] = useState(-1);
    const [serviceFilter, setServiceFilter] = useState(null);
    const [search, setSearch] = useState('');

    const meses = [
        'Enero', 'Febrero', 'Marzo',
        'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre',
        'Octubre', 'Noviembre', 'Diciembre' 
    ]

    function seeDocument(){
        setDocumentShow(true);
    }
    function closeDocument(){
        setDocumentShow(false);
    }
    function clean(){
        setClient({});
        setName('');
        setDocument('');
        setDate(hoy.getDate());
        setMonth(hoy.getMonth() + 1);
        setYear(hoy.getFullYear());
        setPhone('');
        setInstagram('');
    }

    function crearCliente(){
        const newClient = {};
        newClient._id = `cliente:${document}`;
        newClient.nombre = name;
        newClient.documento = document;
        newClient.celular = phone;
        newClient.instagram = instagram;
        newClient.service = service;
        newClient.parsedCumple = `${date}/${month}/${year}`;
        newClient.cumple = new Date(`${year}/${month}/${date}`).getTime();
        newClient.registro = new Date(today);
        newClient.parsedRegistro = today;
        newClient.local = actualLocal.prefix;
        newClient.fecha = new Date().getTime();
        addClient(newClient);
    }

    function editarCliente() {
        const newClient = client;
        newClient.nombre = name;
        newClient.documento = document;
        newClient.celular = phone;
        newClient.instagram = instagram;
        newClient.service = service;
        newClient.parsedCumple = `${date}/${month}/${year}`;
        newClient.cumple = new Date(`${year}/${month}/${date}`).getTime();
        newClient.registro = new Date(today);
        newClient.parsedRegistro = today;
        newClient.local = actualLocal.prefix;
        addClient(newClient);
    }

    function send(){
        if (client._id){
            editarCliente();
        } else {
            crearCliente();
        }
        clean();
    }

    function elegirCliente(c) {
        let servicio = c.documentos ? c.documentos[0].cuerpo[1].nombre : c.service;
        const cumple = new Date(c.cumple);
        setClient(c);
        setName(c.nombre);
        setDocument(c.documento);
        setDate(cumple.getDate());
        setMonth(cumple.getMonth() + 1);
        setYear(cumple.getFullYear());
        setPhone(c.celular);
        setInstagram((c.instagram || ''));
        setService(servicio);
    }

    function whatsapp(celular) {
        const cel = (celular.substring(0, 1) === '+' ? celular : `+54${celular}`);
        window.open(`https://wa.me/${cel}`, '_blank').focus();
    }

    function renderRowClient(client, key){
        const c = new Date(client.cumple);
        const r = new Date(client.fecha || client.registro);
        let cumple = `${c.getDate()}/${c.getMonth() + 1}/${c.getFullYear()}`;
        let registro = `${r.getDate()}/${r.getMonth() + 1}/${r.getFullYear()}`;

        return (
            <div className="w-full p-4 mb-2 bg-white shadow rounded flex items-center justify-between" key={key}>
                <div className="flex flex-col items-start justify-start">
                    <div className="text-xs font-bold">Local y registro</div>
                    <div>{client.documentos ? client.documentos[0].local : client.local}</div>
                    <div className="text-xs font-bold">{registro}</div>
                </div>
                <div className="flex flex-col items-start justify-start">
                    <div className="text-xs font-bold">Nombre y Apellido</div>
                    <div>{client.nombre}</div>
                </div>
                <div>
                    <div className="text-xs font-bold">Celular</div>
                    <div>{client.celular}</div>
                </div>
                <div className="flex flex-col items-start justify-start">
                    <div className="text-xs font-bold">Cumple y documento</div>
                    <div>{cumple}</div>
                    <div className="text-xs font-bold">{client.documento}</div>
                </div>
                <div className="flex flex-col items-start justify-around">
                    <div onClick={() =>whatsapp(client.celular)} className="cursor-pointer text-red-500 font-bold text-sm"><i className="ri-whatsapp-line"></i>Whatsapp</div>
                    <div onClick={()=>elegirCliente(client)} className="cursor-pointer text-red-500 font-bold text-sm"><i className="ri-edit-line"></i>Editar</div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        setClientType(serviceFilter);
    }, [serviceFilter]);

    useEffect(()=>{
        if (clients.length > 0){
            const list = clients.filter(( c => 
                    (!!c.doc.documentos && (c.doc.documentos[0].local === actualLocal.prefix)) 
                || 
                    (!!c.doc.local && c.doc.local === actualLocal.prefix)
                ));
            let listRender = list;
            if (birth !== -1){
                listRender = listRender.filter(c => (new Date(c.doc.cumple).getMonth() + 1 === birth));
            }
            if (reg !== -1){
                listRender = listRender.filter(c => new Date(c.doc.fecha).getMonth() + 1 === reg);
            }

            listRender = listRender.filter(c => (
                    c.doc?.nombre?.toLowerCase().includes(search.toLowerCase())
                    || c.doc?.documento?.toLowerCase().includes(search.toLowerCase())
                    || c.doc?.celular?.toLowerCase().includes(search.toLowerCase())
                ));
            // siempre tiene que estar a lo ultimo antes del setClientList.
            listRender = listRender.sort((a, b) => {
                if (b.doc.fecha - a.doc.fecha > 0){
                    return 1;
                } else if (b.doc.fecha - a.doc.fecha < 0){
                    return -1;
                }

                return 0;
            })
            setClientList(listRender);
        }
    },[clients, reg, birth, actualLocal.prefix, search]);

    useEffect(() => {
        getClients();
    }, [clientType, clientPagination]);

    return (
        <div className="relative w-screen alto bg-gray-300 flex items-center justify-start">
            <div className="w-1/3 alto border-r border-gray-400">
                <div className="w-full flex items-center justify-start px-2 mt-2">
                    <div className="font-bold text-xs">Nombre y Apellido</div>
                </div>
                <div className="w-full flex items-center justify-start mb-4 p-2">
                    <input value={name} onChange={(e) => setName(e.target.value)} placeholder="Nombre Apellido" className="p-2 shadow w-full" type="text"/>
                </div>
                <div className="w-full flex items-center justify-start px-2">
                    <div className="font-bold text-xs">Documento</div>
                </div>
                <div className="w-full flex items-center justify-start mb-4 p-2">
                    <input value={document} onChange={(e) => setDocument(e.target.value)} placeholder="xxxxxxxx" className="p-2 shadow w-full" type="text"/>
                </div>
                <div className="w-full flex items-center justify-start px-2">
                    <div className="font-bold text-xs">Fecha de nacimiento</div>
                </div>
                <div className="w-full flex items-center justify-start mb-4 p-2">
                    <input value={date} onChange={(e) => setDate(e.target.value)} placeholder="DD" className="p-2 shadow w-full" type="text"/>
                    <input value={month} onChange={(e) => setMonth(e.target.value)} placeholder="MM" className="p-2 shadow w-full" type="text"/>
                    <input value={year} onChange={(e) => setYear(e.target.value)} placeholder="YYYY" className="p-2 shadow w-full" type="text"/>
                </div>
                <div className="w-full flex items-center justify-start px-2">
                    <div className="font-bold text-xs">Telefono</div>
                </div>
                <div className="w-full flex items-center justify-start mb-4 p-2">
                    <input value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="+54119999999" className="p-2 shadow w-full" type="text"/>
                </div>
                <div className="w-full flex items-center justify-start px-2">
                    <div className="font-bold text-xs">Instagram</div>
                </div>
                <div className="w-full flex items-center justify-start mb-4 p-2">
                    <input value={instagram} onChange={(e) => setInstagram(e.target.value)} placeholder="arteaflordepiel" className="p-2 shadow w-full" type="text"/>
                </div>
                <div className="w-full flex items-center justify-start px-2">
                    <div className="font-bold text-xs">Servicio</div>
                </div>
                <div className="w-full flex items-center justify-start mb-4 p-2">
                    <select value={service} onChange={(e) => setService(e.target.value)} className="p-2 shadow w-full">
                        <option value="Tatuaje">Tatuaje</option>
                        <option value="Perforación">Perforación</option>
                    </select>
                </div>
                <div className="w-full flex items-center justify-between mb-4 px-2">
                    <button onClick={clean} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Limpiar</button>
                    {client && client.documentos ?
                        <button onClick={seeDocument} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Documentos</button>
                        :
                        null
                    }                    
                    <button onClick={send} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">{client._id ? 'Editar' : 'Crear'}</button>
                </div>
            </div>
            <div className="w-2/3 alto border-gray-400">
                <div className="w-full flex items-center justify-start">
                    <div className="w-full flex items-center justify-center p-2">
                        <select value={serviceFilter} onChange={(e)=> setServiceFilter(e.target.value)} className="p-2 shadow w-full">
                            <option value={null}>Servicio</option>
                            <option value="Tatuaje">Tatuaje</option>
                            <option value="Perforación">Perforación</option>
                        </select>
                    </div>
                    <div className='w-full flex items-center justify-center p-2'>
                    <input placeholder="Buscar..." value={search} onChange={(e)=>setSearch(e.target.value)} className="p-2 shadow w-full" type="text"/>
                    </div>
                </div>
                <div className="alto px-2">
                    {
                        clientList.map((c, i) => renderRowClient(c.doc, i))
                    }
                </div>
            </div>
        </div>
    )   
}
