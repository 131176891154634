import {navigate} from 'hookrouter';
import {useEffect, useState} from 'react';
import { useSesionContext } from '../../app/context';

export default function Login(){
    const miStorage = window.localStorage;
    const {setActualLocal} = useSesionContext()
    const [area, setArea] = useState('');

    function ingresar(a){
        miStorage.setItem('local', a);
        switch(a){
            case 'santafe':
                setActualLocal({name:'Santa Fe', database: 'Santa Fe', prefix: 'Santa Fe', abreviation: 'SF'});
                navigate('/home');
                break;
            case 'guemes':
                setActualLocal({name:'Güemes', database: 'Guemes', prefix: 'Guemes', abreviation: 'G'});
                navigate('/home');
                break;
            case 'costa':
                setActualLocal({name:'Costa', database: 'Costa', prefix: 'Costa', abreviation: 'C'});
                navigate('/home');
                break;
           case 'sanmartin':
                setActualLocal({name:'San Martin', database: 'San Martin', prefix: 'San Martin', abreviation: 'SM'});
                navigate('/home');
                break;
            default:
                setArea('');
                break;
        }
    }

    useEffect(() => {
        const local = miStorage.getItem('local');
        if (!!local) {
            ingresar(local);
        }
    }, []);

    return (
        <div className="w-screen h-screen flex items-center justify-center bg-gray-500">
            <div className="w-1/3 h-1/3 bg-gray-50 shadow rounded-xl p-4 flex flex-col items-center justify-center">
                <h1 className="font-bold text-3xl">Código de la sucursal</h1>
                <input value={area} onChange={(e) => setArea(e.target.value)} className="w-3/4 p-2 shadow rounded my-2" type="text" placeholder="sucursal"/>
                <button onClick={() => ingresar(area)} className="bg-red-500 shadow rounded p-4 text-white hover:bg-red-700">Ingresar</button>
            </div>

        </div>
    )
}
