import { useEffect, useState } from "react";
import { useSesionContext } from "../../app/context";

export default function ControllerRegister(){
    const { addRegister, changeNotes, connect, register, today, box, actualLocal, notes } = useSesionContext(); 
    const [noteActual, setNoteActual] = useState("");

    useEffect(() => {
        setNoteActual(notes.text);
    }, [notes.text]);
    return (
        <div className="w-full bg-gray-50 h-60 flex flex-col items-start justify-start p-2">
            <h1 className="font-bold">Notas</h1>
            <textarea onBlur={()=> changeNotes(noteActual)} value={noteActual} onChange={(e)=> setNoteActual(e.target.value)} className="w-full h-full resize-none"></textarea>
        </div>
    )
}