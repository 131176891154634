import { useState, useRef } from "react";
import { useSesionContext } from "../../app/context";

export default function ControllerRegister(){
    const test = useRef();
    const { addRegister, workers, products, closeBox } = useSesionContext(); 
    const [active, setActive] = useState(0);
    // cerrar caja
    const [cambio, setCambio] = useState(0);
    const [resto, setResto] = useState(0);
    // movimientos
    const [desc, setDesc] = useState('');
    const [search, setSearch] = useState('');
    const [searchItem, setSearchItem] = useState(null);
    const [precio, setPrecio] = useState('');
    const [descuento, setDescuento] = useState(0);
    const [amount, setAmount] = useState(0);
    const [cant, setCant] = useState('');
    const [item, setItem] = useState('');
    const [card, setCard] = useState(false);
    const [searchWorker, setSearchWorker] = useState('');
    const [worker, setWorker] = useState(null);
    
    function handleClose(){
        closeBox(cambio, resto);
        setResto(0);
        setCambio(0);
        setActive(0);
        setDescuento(0);
    }

    function handleSeleccion(e){
        const prod = products.find(el => el.id === e.target.value);
        if (prod){
            if (prod.doc.nombre === 'Tatuaje'){
                setActive(3);
            }
            if (prod.doc.nombre ==='Perforación'){
                setActive(4);
            }
            if (prod.doc.nombre ==='Ingresar a Caja'){
                setActive(5);
            }
            setSearchItem(prod.doc);
        }
    }
    function handleCancel(){
        setDesc('');
        setSearchItem(null);
        setWorker(null);
        setCant('');
        setPrecio('');
        setAmount(0);
        setSearch('');
        setSearchWorker('');
        setCard(false);
        setActive(0);
        setDescuento(0);
        setItem('');
    }

    function handleSeleccionWorker(e){
        const worker = workers.find(el => el.id === e.target.value);
        if (worker){
            setWorker(worker.doc);
        }
    }

    function handleEgreso() {
        const newCant = (cant === '' ? 1 : cant);

        if (active === 0){
            const doc = {
                desc: `${searchItem.nombre}`,
                base: parseInt(searchItem.precio),
                amount: (( parseInt(searchItem.precio) * parseInt(newCant) ) - ( parseInt(searchItem.precio * parseInt(descuento) / 100) )),
                type: 'Ingreso',
                cantidad: newCant,
                tarjeta:card
            };

            addRegister(doc);
        } else if (active === 1) {
            const doc = {
                desc: desc,
                amount: parseInt(amount),
                type:'Egreso'
            };
            addRegister(doc);
        } else if (active === 3) {
            const doc = {
                desc: `Tatuaje de ${worker.sobrenombre}`,
                sobrenombre: worker.sobrenombre,
                empleado: worker._id,
                amount: parseInt(amount),
                tarjeta:card,
                tipo:'Tatuaje',
                type:'Ingreso'
            };
            addRegister(doc);
            
        } else if (active === 4) {
            const doc = {
                desc: `Perforación de ${worker.sobrenombre}`,
                empleado: worker._id,
                sobrenombre: worker.sobrenombre,
                base: parseInt(searchItem.precio),
                amount: (parseInt(searchItem.precio) * parseInt(cant)) - (parseInt(searchItem.precio * parseInt(descuento) / 100)),
                tarjeta:card,
                cantidad: parseInt(cant),
                tipo:'Perforación',
                type:'Ingreso'
            };
            addRegister(doc);
        } else if (active === 5) {
            let doc = {};
            if (item !== '') {
                doc = {
                    desc: desc,
                    item: {item},
                    base: parseInt(precio),
                    amount: (parseInt(precio) * parseInt(cant)) - (parseInt(precio * parseInt(descuento) / 100)),
                    type:'Ingreso',
                    cantidad: cant,
                    tarjeta:card
                };
            } else {
                doc = {
                    desc: desc,
                    base: parseInt(precio),
                    amount: (parseInt(precio) * parseInt(cant)) - (parseInt(precio * parseInt(descuento) / 100)),
                    tarjeta: card,
                    cantidad: parseInt(cant),
                    type:'Ingreso'
                };
            }
            addRegister(doc);
        } else if (active === 6) {
            const doc = {
                amount: parseInt(precio),
                desc: `Pago a ${worker.sobrenombre}`,
                empleado: worker,
                card: false,
                tipo: 'Pago',
                type:'Egreso',
                sobrenombre: worker.sobrenombre
            };
            addRegister(doc);
        }
        handleCancel();
    }

    function renderOptions(){
        return (
            <datalist id="options">
                {
                    products.map((p,i) => <option value={p.doc._id} key={i}>{p.doc.nombre} - ${p.doc.precio}</option>)
                }
            </datalist>
        )
    }

    function renderOptionsTatu(){
        return (
            <datalist id="tatu">
                {
                    workers.filter(el => el.doc.cargo === 'Tatuador').map((p,i) => <option value={p.doc._id} key={i}>{p.doc.nombre}</option>)
                }
            </datalist>
        )
    }

    function renderOptionsPerfo(){
        return (
            <datalist id="perfo">
                {
                    workers.filter(el => el.doc.cargo === 'Perforador').map((p,i) => <option value={p.doc._id} key={i}>{p.doc.nombre}</option>)
                }
            </datalist>
        )
    }

    function renderOptionsEncargado(){
        return (
            <datalist id="empleado">
                {
                    workers.map((p,i) => <option value={p.doc._id} key={i}>{p.doc.nombre}</option>)
                }
            </datalist>
        )
    }

    function contentRender(){
        if (active === 0){
            return (
                <div className="w-full h-full flex flex-col items-center justify-center p-4">
                    <div className="w-full flex items-center justify-start mb-4">
                        {
                            searchItem !== null ?
                                <div className="w-full flex items-center justify-between"><span>{searchItem.nombre}</span><span>${searchItem.precio}</span></div>
                            :
                            <>
                                <input ref={test} list="options" placeholder="Buscar..." onSelect={handleSeleccion} value={search} onBlur={handleSeleccion} onChange={(e)=>setSearch(e.target.value)} className="p-2 shadow w-full" type="text"/>
                                {renderOptions()}
                            </>
                        }
                    </div>
                    {
                        searchItem !== null ?
                        <>
                        <div className="w-full flex items-center justify-start mb-4">
                            <input value={cant} onChange={(e) => setCant(e.target.value)} placeholder="Cantidad" className="p-2 shadow w-full" type="text"/>
                        </div>
                        <div className="w-full flex items-center justify-start mb-4">
                            <select value={card} onChange={(e)=>setCard(e.target.value)} className="p-2 shadow w-full">
                                <option value={false}>Efectivo</option>
                                <option value={true}>Tarjeta</option>
                            </select>
                        </div>
                        <div className="w-full flex items-center justify-start mb-4">
                            <select value={descuento} onChange={(e) => setDescuento(parseInt(e.target.value))} className="p-2 shadow w-full">
                                <option value={0}>Descuento</option>
                                <option value={10}>%10</option>
                                <option value={15}>%15</option>
                                <option value={20}>%20</option>
                            </select>
                        </div>
                        </>
                        :
                        null
                    }
                    {
                        searchItem !== null ?
                        <>
                        <div className="w-full flex items-center justify-between mb-4">
                            <button onClick={handleCancel} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Cancelar</button>
                            <button onClick={handleEgreso} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Agregar Movimiento</button>
                        </div>
                        </>
                        :
                        null
                    }
                </div>
            )
        }
        if (active === 1){
            return (
                <div className="w-full h-full flex flex-col items-center justify-center p-4">
                    <div className="w-full flex items-center justify-start mb-4">
                        <button onClick={()=>setActive(6)} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Pago</button>
                    </div>
                    <div className="w-full flex items-center justify-start mb-4">
                        <span className="w-1/5 font-bold">Descripción</span><input value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder="Ej: Art. limpieza" className="p-2 shadow ml-2" type="tel"/>
                    </div>
                    <div className="w-full flex items-center justify-start mb-4">
                        <span className="w-1/5 font-bold">Monto</span><input value={amount} onChange={(e)=> setAmount(e.target.value)} placeholder="0" className="p-2 shadow ml-2" type="tel"/>
                    </div>
                    <div className="w-full flex items-center justify-center">
                        <button onClick={handleEgreso} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Generar Egreso</button>
                    </div>
                </div>
            )
        }
        if (active === 2){
            return (
                <div className="w-full h-full flex flex-col items-center justify-center p-4">
                    <div className="w-full flex items-center justify-start mb-4">
                        <span className="w-1/5 font-bold">Cambio</span><input value={cambio} onChange={(e)=> setCambio(e.target.value)} placeholder="0" className="p-2 shadow ml-2" type="tel"/>
                    </div>
                    <div className="w-full flex items-center justify-start mb-4">
                        <span className="w-1/5 font-bold">Sobrante</span><input value={resto} onChange={(e)=> setResto(e.target.value)} placeholder="0" className="p-2 shadow ml-2" type="tel"/>
                    </div>
                    <div className="w-full flex items-center justify-center">
                        <button onClick={handleClose} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Cerrar Caja</button>
                    </div>
                </div>
            )
        }
        if (active === 3){
            return (
                <div className="w-full h-full flex flex-col items-center justify-start p-4">
                    <div className="w-full flex items-center justify-start mb-4">Tatuaje</div>
                    <>
                        {
                            worker ?
                                <div className="w-full flex items-center justify-between">{worker.sobrenombre}</div>
                            :
                                <>
                                    <input list="tatu" placeholder="Tatuador" value={searchWorker} onBlur={handleSeleccionWorker} onChange={(e)=>setSearchWorker(e.target.value)} className="p-2 shadow w-full" type="text"/>
                                    {renderOptionsTatu()}
                                </>
                        }
                        
                        {
                            worker ?
                            <>
                                <div className="w-full flex items-center justify-start mb-4">
                                    <span className="w-1/5 font-bold">Monto</span><input value={amount} onChange={(e)=> setAmount(e.target.value)} placeholder="0" className="p-2 shadow ml-2" type="tel"/>
                                </div>
                                <div className="w-full flex items-center justify-start mb-4">
                                    <select value={card} onChange={(e)=>setCard(e.target.value)} className="p-2 shadow w-full">
                                        <option value={false}>Efectivo</option>
                                        <option value={true}>Tarjeta</option>
                                    </select>
                                </div>
                                <div className="w-full flex items-center justify-between mb-4">
                                    <button onClick={handleCancel} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Cancelar</button>
                                    <button onClick={handleEgreso} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Agregar Movimiento</button>
                                </div>
                            </>
                            :
                                null
                        }
                    </>
                </div>
            )
        }
        if (active === 4){
            return (
                <div className="w-full h-full flex flex-col items-center justify-start p-4">
                    <div className="w-full flex items-center justify-start mb-4">Perforación</div>
                    <>
                        {
                            worker ?
                                <div className="w-full flex items-center justify-between">{worker.sobrenombre}</div>
                            :
                                <>
                                    <input list="perfo" placeholder="Perforador" value={searchWorker} onBlur={handleSeleccionWorker} onChange={(e)=>setSearchWorker(e.target.value)} className="p-2 shadow w-full" type="text"/>
                                    {renderOptionsPerfo()}
                                </>
                        }
                        {
                            worker ?
                            <>
                                <div className="w-full flex items-center justify-start mb-4">
                                    <input value={cant} onChange={(e) => setCant(e.target.value)} placeholder="Cantidad" className="p-2 shadow w-full" type="text"/>
                                </div>
                                <div className="w-full flex items-center justify-start mb-4">
                                    <select value={card} onChange={(e)=>setCard(e.target.value)} className="p-2 shadow w-full">
                                        <option value={false}>Efectivo</option>
                                        <option value={true}>Tarjeta</option>
                                    </select>
                                </div>
                                <div className="w-full flex items-center justify-start mb-4">
                                    <select value={descuento} onChange={(e) => setDescuento(parseInt(e.target.value))} className="p-2 shadow w-full">
                                        <option value={0}>Descuento</option>
                                        <option value={10}>%10</option>
                                        <option value={15}>%15</option>
                                        <option value={20}>%20</option>
                                    </select>
                                </div>
                                <div className="w-full flex items-center justify-between mb-4">
                                    <button onClick={handleCancel} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Cancelar</button>
                                    <button onClick={handleEgreso} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Agregar Movimiento</button>
                                </div>
                            </>
                            :
                                null
                        }
                    </>
                </div>
            )
        }
        else if (active === 5){
            return (
                <div className="w-full h-full flex flex-col items-center justify-start p-4">
                    <div className="w-full flex items-center justify-start mb-4">Ingresar a caja</div>
                    <>
                        <div className="w-full flex items-center justify-start mb-4">
                            <input value={desc} onChange={(e) => setDesc(e.target.value)} placeholder="Descripción" className="p-2 shadow w-full" type="text"/>
                        </div>
                        <div className="w-full flex items-center justify-start mb-4">
                            <input value={precio} onChange={(e) => setPrecio(e.target.value)} placeholder="Precio" className="p-2 shadow w-full" type="text"/>
                        </div>
                        <div className="w-full flex items-center justify-start mb-4">
                            <input value={cant} onChange={(e) => setCant(e.target.value)} placeholder="Cantidad" className="p-2 shadow w-full" type="text"/>
                        </div>
                        <div className="w-full flex items-center justify-start mb-4">
                            <input list="options" placeholder="Item (puede estar vacio)" onSelect={(e)=>setItem(e.target.value)} value={item} onChange={(e)=>setItem(e.target.value)} className="p-2 shadow w-full" type="text"/>
                            {renderOptions()}
                        </div>
                        <div className="w-full flex items-center justify-start mb-4">
                            <select value={card} onChange={(e)=>setCard(e.target.value)} className="p-2 shadow w-full">
                                <option value={false}>Efectivo</option>
                                <option value={true}>Tarjeta</option>
                            </select>
                        </div>
                        <div className="w-full flex items-center justify-start mb-4">
                            <select value={descuento} onChange={(e) => setDescuento(parseInt(e.target.value))} className="p-2 shadow w-full">
                                <option value={0}>Descuento</option>
                                <option value={10}>%10</option>
                                <option value={15}>%15</option>
                                <option value={20}>%20</option>
                            </select>
                        </div>
                        <div className="w-full flex items-center justify-between mb-4">
                            <button onClick={handleCancel} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Cancelar</button>
                            <button onClick={handleEgreso} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Agregar Movimiento</button>
                        </div>
                    </>
                </div>
            )
        } else if (active === 6) {
            return (
                <div className="w-full h-full flex flex-col items-center justify-center p-4">
                    {
                        worker ?
                            <div className="w-full flex items-center justify-between">{worker.sobrenombre}</div>
                        :
                            <>
                                <input list="empleado" placeholder="Empleados" value={searchWorker} onBlur={handleSeleccionWorker} onChange={(e)=>setSearchWorker(e.target.value)} className="p-2 shadow w-full" type="text"/>
                                {renderOptionsEncargado()}
                            </>
                    }
                    {
                        worker ?
                        <>
                            <div className="w-full flex items-center justify-start mb-4">
                                <input value={precio} onChange={(e) => setPrecio(e.target.value)} placeholder="Precio" className="p-2 shadow w-full" type="text"/>
                            </div>
                            <div className="w-full flex items-center justify-between mb-4">
                                <button onClick={handleCancel} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Cancelar</button>
                                <button onClick={handleEgreso} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Pagar</button>
                            </div>
                        </>
                        :
                            null
                    }
                </div>
            )
        }
        else {
            return null;
        }
    }
    return (
        <div className="w-full bg-gray-50 min-h-60 h-auto overflow-y-hidden">
            <div className="w-full h-10 flex items-center justify-between divide-x divide-red-100">
                <div onClick={() => setActive(0)} className={`transition duration-150 w-full h-full flex items-center justify-center hover:bg-red-500 hover:text-white cursor-pointer ${active === 0 ? 'bg-red-500 text-white' : ''}`}>Ingreso</div>
                <div onClick={() => setActive(1)} className={`transition duration-150 w-full h-full flex items-center justify-center hover:bg-red-500 hover:text-white cursor-pointer ${active === 1 ? 'bg-red-500 text-white' : ''}`}>Egreso</div>
                <div onClick={() => setActive(2)} className={`transition duration-150 w-full h-full flex items-center justify-center hover:bg-red-500 hover:text-white cursor-pointer ${active === 2 ? 'bg-red-500 text-white' : ''}`}>Cerrar Caja</div>
            </div>
            {contentRender()}
        </div>
    )
}