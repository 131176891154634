import { createContext, useState, useContext } from 'react';
import superagent from 'superagent';

const sessionContext = createContext({});

export function SesionProvider ({ children }) {
    const [actualLocal, setActualLocal] = useState(null);
    const dev = false;
    const ip = (dev ? 'http://localhost:5984/demo' : 'https://vps-1858514-x.dattaweb.com/arteflordepiel');
    // const ip = 'http://200.58.99.151:5984/arteflordepiel'
    const authCode = (dev ? 'cHJ1ZWJhOnBydWViYQ==' : 'YXJ0ZTpmbG9y');
    const [dataTable,setDataTable] = useState({
        total: 0,
        tarjeta: 0,
        efectivo: 0,
        pagos: 0,
        gastos: 0,
        neto: 0,
        cambio: 0,
        sobrante: 0
    });
    // const [tablaEmpleados, setTablaEmpleados] = useState({});
    const [notes, setNotes] = useState("");
    const [box, setBox] = useState({});
    const [boxToday, setBoxToday] = useState(null);
    const [register, setRegister] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [products, setProducts] = useState([]);
    const [clients, setClients] = useState([]);
    const [clientType, setClientType] = useState('');
    const [clientPagination, setClientPagination] = useState(0);
    const [categorias, setCategorias] = useState([]);
    const d = new Date();
    const today = `${d.getFullYear()}-${d.getMonth() + 1 }-${d.getDate()}`
    // se conecta con la dase de datos , carga la primer nota y revisa el cambio del dia anterior
    async function connect(){
        await superagent.get(`${ip}/nota:${actualLocal.prefix}`)
        .set('Accept', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .end((err, res)=>{
            if (res.ok){
                setNotes(res.body);
            } else {
                console.log(err);
            }
        })
        superagent.get(`${ip}/caja:${actualLocal.prefix}-${today}`)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .end((err, res)=>{
            if (res.ok){
                setBoxToday(res.body);
            } else {
                console.log('no hay caja');
            }
        })
    }

    function getWorkers(){
        superagent.get(`${ip}/_partition/empleado/_all_docs?include_docs=true`)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .end((req,res)=>{
            if (res.ok){
                setWorkers(res.body.rows);
            }
        })
    }

    function getCategorias(){
        superagent.get(`${ip}/config:categorias`)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .end((req,res)=>{
            if (res.ok){
                setCategorias(res.body.categorias);
            }
        })
    }

    function addClient(newClient){
        const h = new Date();
        const time = `${h.getHours()}:${h.getMinutes()}:${h.getSeconds()}`;
        let url = `${ip}/${newClient._id}`;
        const arrayToday = today.split('-');
        const arrayTime = time.split(':');
        if (!newClient.date){
            newClient.date = [...arrayToday, ...arrayTime];
        }
        superagent.put(url)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .send(newClient)
        .end((err, res) => {
            if (res.ok){
                getClients();
            } else {
                console.log(err);
            }
        })
    }
    function getClients(){
        const cant = 10;
        let tipo = '';

        if (clientType === 'Tatuaje') {
            tipo = '_tattoo';
        }
        
        if (clientType === 'Perforación') {
            tipo = '_perfo';
        }

        const _view = `by_local${tipo}`;
        const key = `endkey=["${actualLocal.name}"]&startkey=["${actualLocal.name}",{}]`;
    
        superagent.get(`${ip}/_partition/cliente/_design/by_local/_view/${_view}?include_docs=true&${key}&descending=true`)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .end((req,res)=>{
            if (res.ok){
                setClients(res.body.rows);
            }
        })
    }

    function getProducts(){
        superagent.get(`${ip}/_partition/deposito/_all_docs?include_docs=true`)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .end((req,res)=>{
            if (res.ok){
                setProducts(res.body.rows);
            }
        })
    }

    function addProduct(newProduct){
        superagent.put(`${ip}/${newProduct._id}`)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .send(newProduct)
        .end((err, res)=>{
            if (res.ok){
                getProducts();
            } else {
                console.log(err);
            }
        })
    }

    function getCambio(ayer = null) {
        const hasCambio = register.some(el => el.desc === 'Cambio día anterior.');
        if (!hasCambio){
            let d;
            if (ayer){
                d = new Date(ayer);
            } else {
                d = new Date();
            }
            d.setDate(d.getDate() - 1);
            const yesterday = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;
            superagent.get(`${ip}/caja:${actualLocal.prefix}-${yesterday}`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`)
            .end((err, res)=>{
                if (res.ok){
                    setBox(res.body);
                    const mov = {cambio: res.body.cambio, cantidad: 1, desc:'Cambio día anterior.', type:'Ingreso', card:false, amount:res.body.cambio};
                    addRegister(mov);
                } else {
                    getCambio(d);
                    console.log(err);
                }
            })
        }
    }
    function getRegister(){
        superagent.post(`${ip}/_partition/register/_all_docs?include_docs=true`)
        .set('Accept', 'application/json')
        .send({
            "selector":{
                "local": {"$eq": actualLocal.prefix},
                "day": {"$eq": today}
            }
        })
        .set('Authorization', `Basic ${authCode}`)
        .end((err,res)=>{
            if (res.ok){
                if (res.body.rows.length > 0){
                    const lista = res.body.rows.filter(el => el.doc.local === actualLocal.prefix && el.doc.day === today);
                    if (lista.length > 0) {
                        let listaDocs = [];
                        lista.forEach(element => {
                            listaDocs.push(element.doc);
                        });
                        setRegister(listaDocs);
                    } else {
                        getCambio();
                    }
                }
            } else {
                console.log(err);  
            }
        })
    }

    function changeNotes(newText){
        const newNote = notes;
        newNote.text = newText;
        superagent.put(`${ip}/nota:${actualLocal.prefix}`)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .send(newNote)
        .end((err, res)=>{
            if (res.ok){
                newNote._rev = res.body.rev;
                setNotes(newNote);
            } else {
                console.log(err);
            }
        })
    }

    function closeBox(cambio, sobrante){
        let reg = {
            sobrante: parseInt(sobrante),
            cambio: parseInt(cambio),
            total: parseInt(dataTable.total),
            tarjeta: parseInt(dataTable.tarjeta),
            efectivo: parseInt(dataTable.efectivo),
            pagos: parseInt(dataTable.pagos),
            gastos: parseInt(dataTable.gastos),
            neto: parseInt(dataTable.neto)
        };
        const h = new Date();
        const time = `${h.getHours()}:${h.getMinutes()}:${h.getSeconds()}`;
        const arrayToday = today.split('-');
        const arrayTime = time.split(':');
        reg.date = [...arrayToday, ...arrayTime];
        const url = `${ip}/caja:${actualLocal.prefix}-${today}`;
        if (boxToday && boxToday._rev){
            reg._rev = boxToday._rev;
        }
        superagent.put(url)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .send(reg)
        .end((err, res)=>{
            if (res.ok){
                reg._rev = res.body.rev;
                setBoxToday(reg);
            } else {
                console.log(err);
            }
        })
    }

    function delRegister(reg){
        const url = `${ip}/${reg._id}`;
        let data = reg;
        data._deleted = true;
        superagent.put(url)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .send(reg)
        .end((err, res) => {
            if (res.ok){
                const newReg = reg;
                newReg._rev = res.body.rev;
                const docs = register.filter(e => e._id !== reg._id);
                setRegister(docs);
            } else {
                console.log(err);
            }
        })
    }

    function addRegister(reg){
        const h = new Date();
        const time = `${h.getHours()}:${h.getMinutes()}:${h.getSeconds()}`;
        let url = `${ip}/register:${actualLocal.prefix}-${today}-${time}`;
        if (reg._id){
            url = `${ip}/${reg._id}`;
        }
        const arrayToday = today.split('-');
        const arrayTime = time.split(':');
        if (!reg.local){
            reg.local = actualLocal.prefix;
        }
        if (!reg.date){
            reg.date = [...arrayToday, ...arrayTime];
        }
        if (!reg.day){
            reg.day = today;
        }
        superagent.put(url)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${authCode}`)
        .send(reg)
        .end((err, res) => {
            if (res.ok){
                const newReg = reg;
                newReg._rev = res.body.rev;
                const editReg = register.find(el => el._id === newReg._id);
                let docs = [];
                if (editReg){
                    let newDocs = register.filter(el => el._id !== newReg._id);
                    newDocs.push(newReg);
                    docs = newDocs;
                } else {
                    newReg._id = `register:${actualLocal.prefix}-${today}-${time}`;
                    docs = [ ...register, newReg];
                }
                setRegister(docs);
            } else {
                console.log(err);
            }
        })
    }

    return (
        <sessionContext.Provider value={{addClient, addRegister, changeNotes, connect, closeBox, 
        setDataTable, delRegister, setActualLocal, getClients, setClientType, clientType, clientPagination,
        getWorkers, getRegister, getProducts, getCambio, getCategorias, addProduct,
        actualLocal, workers, register, today, box, products, notes, clients, boxToday, categorias }}>
            {children}
        </sessionContext.Provider>
    )
}

export function useSesionContext(){
    const consumer = useContext(sessionContext);

    return consumer;
}