import { useEffect, useState } from "react";
import {navigate} from 'hookrouter';
import { useSesionContext } from "../../app/context"

export default function AddProduct(){
    const {categorias, getCategorias,today, addProduct} = useSesionContext();
    const [nombre, setNombre] = useState('');
    const [precio, setPrecio] = useState('');
    const [categoria, setCategoria] = useState('');

    function cancelar(){
        setNombre('');
        setPrecio('');
        setCategoria('');
        navigate('/home');
    }

    function crear(){
        const newProduct = {};
        newProduct._id = `deposito:${nombre.trim()}`;
        newProduct.base = parseInt(precio);
        newProduct.alerta = 0;
        newProduct.precio = parseInt(precio);
        newProduct.amount = parseInt(precio);
        newProduct.categoria = categoria;
        newProduct.tipo = 'Producto';
        newProduct.nombre = nombre.trim();
        newProduct.fecha = new Date().getTime();
        newProduct.parsedFecha = today;

        addProduct(newProduct);
        navigate('/home');
    }

    useEffect(() => {
        getCategorias();
    }, [])
    return (
        <div className="w-full alto flex items-center justify-center bg-gray-200">
            <div className="flex flex-col items-center justify-center w-3/5 h-3/5 bg-gray-100 m-20">
                <div className="font-bold">Agregar producto</div>
                <div className="w-full flex items-center justify-start px-2">
                    <div className="font-bold text-xs">Nombre</div>
                </div>
                <div className="w-full flex items-center justify-start mb-4 p-2">
                    <input value={nombre} onChange={(e) => setNombre(e.target.value)} placeholder="Ej: Pipa de agua" className="p-2 shadow w-full bg-white" type="text"/>
                </div>
                <div className="w-full flex items-center justify-start px-2">
                    <div className="font-bold text-xs">Precio</div>
                </div>
                <div className="w-full flex items-center justify-start mb-4 p-2">
                    <input value={precio} onChange={(e) => setPrecio(e.target.value)} placeholder="Ej: 500" className="p-2 shadow w-full bg-white" type="text"/>
                </div>
                <div className="w-full flex items-center justify-start px-2">
                    <div className="font-bold text-xs">Categoria</div>
                </div>
                <div className="w-full flex items-center justify-start mb-4 p-2">
                    <input value={categoria} onChange={(e) => setCategoria(e.target.value)} list="categorias" placeholder="Ej: Art. Fumadores" className="p-2 shadow w-full bg-white" type="tel"/>
                </div>
                <datalist id="categorias">
                    {categorias.map((c,i)=><option key={i} value={c}>{c}</option>)}
                </datalist>
                <div className="w-full flex items-center justify-between mb-4 px-2">
                    <button onClick={cancelar} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Cancelar</button>
                    <button onClick={crear} className="p-2 bg-red-500 text-white hover:bg-red-600 cursor-pointer">Agregar</button>
                </div>
            </div>
        </div>
    )
}